import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { useMeta, useContentfulSection, appColors } from "utils"

import { PrimaryNav, AppStickyNav, MobileNav } from "src/components/navigation"

import { AppBeta } from "src/sections/app"

import { Layout, Seo } from "components/common"

const FormsBeta = () => {
  const { app_doc, page } = useStaticQuery(query)
  const meta = useMeta(page)

  const { formsBeta_hero } = useContentfulSection(page.sections)
  const slug = "forms"

  return (
    <StyledComparePage slug={slug}>
      <Layout>
        <Seo {...meta} />

        <MobileNav slug={slug} />
        <PrimaryNav slug={slug} />
        <div className="nav-background">
          <AppStickyNav
            disabled={app_doc.disabled}
            slug={slug}
            salesforceAppexchangeUrl={app_doc.salesforceAppexchangeUrl}
          />
        </div>

        <AppBeta {...formsBeta_hero} />
      </Layout>
    </StyledComparePage>
  )
}

const StyledComparePage = styled.div`
  --app-theme-color: ${({ slug }) => appColors[slug].dark};
  .nav-background {
    --section-background-color: none;
  }
`

export const query = graphql`
  {
    page: contentfulPage(pageId: { eq: "forms_beta" }) {
      ...Page
    }
    app_doc: contentfulApp(slug: { eq: "forms" }) {
      ...App
    }
  }
`

export default FormsBeta
